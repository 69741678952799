import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ 
    title = "SafeD – 스마트안전관리시스템", 
    description = "세이프디(SafeD)는 산업현장(건설, 플랜트, 제조시설, 서비스, 유지관리 등)에서 안전에 필요한 소프트웨어 플랫폼을 제공하여 중대재해처벌법의 의무사항과 안전보건관리체계를 구축하여 일상의 풍요로운 삶과 행복한 미래를 함께 만들고 있습니다.", 
    keywords = "세이프디, SafeD, 위험성평가, 스마트위험성평가, 건설안전, 안전관리, 스마트안전관리, 안전관리시스템, 안전관리솔루션", 
    url = "https://safed.kr",
    image = "https://safed.kr/oglogo.png"
}) => {
    return (
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph 태그 */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        
        {/* Twitter Card 태그 (선택 사항) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
    </Helmet>
    );
};

export default SEO;
